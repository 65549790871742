import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { QuestQuillItem, QuestQuillTypes } from '../types/types';
import './ImageDialog.css';
import ImageShowcase from './ImageShowcase';

type MarkerType = QuestQuillTypes | 'marker' | 'selected';

const textColors = {
  quest: '#c9f1d6',
  personororg: '#f8ebd3',
  place: '#f1ddda',
  miscitem: '#dde7ee',
  logentry: '#f5f5f5',
  "marker": '#d6c9f1',
  "selected": '#fff'
};

const circleColors = {
  quest: '#284',
  personororg: '#d92',
  place: '#b54',
  miscitem: '#58a',
  logentry: '#ccc',
  marker: '#85a',
  "selected": '#fff'
};

const sizeParams = {
  small: { radius: 0.3, fontSize: "0.8px", circleStrokeWidth: 0.1, textStrokeWidthBackground: 0.08, textStrokeWidthForeground: 0.02, yOffset: 0.2, xOffset: 0.5 },
  medium: { radius: 0.5, fontSize: "1.1px", circleStrokeWidth: 0.2, textStrokeWidthBackground: 0.15, textStrokeWidthForeground: 0.05, yOffset: 0.3, xOffset: 0.8 },
  large: { radius: 0.8, fontSize: "1.6px", circleStrokeWidth: 0.3, textStrokeWidthBackground: 0.2, textStrokeWidthForeground: 0.08, yOffset: 0.5, xOffset: 1.1 }
}

interface ResolvedMarker {
  markerId: string;
  type: QuestQuillTypes|'marker';
  name: string;
  position: { x: number, y: number };
  link: QuestQuillItem|undefined;
}

interface ShowMarker {
  markerId?: string;
  type: MarkerType;
  name: string;
  position: { x: number, y: number };
  link?: QuestQuillItem;
}

function ImageDialog({
  imageFileUrl,
  item,
  allItems,
  openItem,
  close,
  addMarker,
  removeMarker
} : {
  imageFileUrl: string,
  item: QuestQuillItem,
  allItems: QuestQuillItem[],
  openItem: (itemId: string) => void,
  close: () => void,
  addMarker: (itemId: string, toItemId: string|undefined, name: string, x: number, y: number) => void,
  removeMarker: (itemId: string, markerId: string) => void
}) {
  const markers: ResolvedMarker[] = useMemo(() =>
    Object.entries(item.markers)
      .map(([markerId, marker]) => ({ id: markerId, link: allItems.find(i => i.id === marker.link), marker: marker }))
      .map(l => ({
        markerId: l.id,
        name: l.marker.name || l.link?.name || "",
        type: (l.link?.type || 'marker') as QuestQuillTypes|'marker',
        position: l.marker,
        link: l.link
      }))
  , [item, allItems]);
  const potentialLinksGrouped = useMemo(() => {
    const perType = [
      { type: 'quest', heading: 'QUESTS', items: [] as QuestQuillItem[] },
      { type: 'personororg', heading: 'PERSON/ORG', items: [] as QuestQuillItem[] },
      { type: 'place', heading: 'PLACES', items: [] as QuestQuillItem[] },
      { type: 'miscitem', heading: 'MISC ITEM', items: [] as QuestQuillItem[] },
      { type: 'logentry', heading: 'LOG ENTRY', items: [] as QuestQuillItem[] },
    ];
    allItems.filter(i => i.id !== item.id && !i.archived).forEach(item => {
      perType.find(t => t.type === item.type)?.items.push(item);
    });
    return perType;
  }, [item, allItems]);

  const [markerBeingAdded, setMarkerBeingAdded] = useState<{ x: number, y: number, link?: string, name?: string, overrideName?: boolean }>();
  const [selectedMarkerId, setSelectedMarkerId] = useState<string>();
  const [imageAspectRatio, setImageAspectRatio] = useState<number>(1);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [markerSize, setMarkerSize] = useState<keyof typeof sizeParams>("medium");
  const [keepOpen, setKeepOpen] = useState<boolean>(false);
  const [hideMarkers, setHideMarkers] = useState<boolean>(false);
  const [showcase, setShowcase] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const resizeImage = () => {
      const container = containerRef.current;
      const img = imgRef.current;
      const svg = svgRef.current;

      if (!container || !img || !svg) {
        return;
      }

      const containerAspectRatio = container.offsetWidth / container.offsetHeight;
      const imageAspectRatio = img.naturalWidth / img.naturalHeight;

      if (imageAspectRatio > containerAspectRatio) {
        img.style.width = '100%';
        img.style.height = `calc(90vw / ${imageAspectRatio})`;
        img.style.left = '0';
        img.style.top = `calc((90vh - (90vw / ${imageAspectRatio})) / 2)`
        svg.style.width = '100%'
        svg.style.height = `calc(90vw / ${imageAspectRatio})`;
        svg.style.left = '0';
        svg.style.top = `calc((90vh - (90vw / ${imageAspectRatio})) / 2)`
      } else {
        img.style.width = `calc(90vh * ${imageAspectRatio})`;
        img.style.height = '100%'
        img.style.left = `calc((90vw - (90vh * ${imageAspectRatio})) / 2)`;
        img.style.top = '0';
        svg.style.width = `calc(90vh * ${imageAspectRatio})`;
        svg.style.height = '100%'
        svg.style.left = `calc((90vw - (90vh * ${imageAspectRatio})) / 2)`;
        svg.style.top = '0';
      }

      setImageAspectRatio(imageAspectRatio);
    };

    // Resize image on load
    const img = imgRef.current;
    if (img?.complete) {
      resizeImage();
    } else {
      img!.onload = resizeImage;
    }

    // Resize image on window resize
    window.addEventListener('resize', resizeImage);
    return () => {
      window.removeEventListener('resize', resizeImage);
    };
  }, []);

  useEffect(() => {
    const keyHandler = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        if (isEditing) {
          setIsEditing(false);
          setMarkerBeingAdded(undefined);
          setSelectedMarkerId(undefined);
        } else {
          close();
        }
      }
    };
    window.addEventListener("keydown", keyHandler);
    return () => {
      window.removeEventListener("keydown", keyHandler);
    }
  }, [isEditing, setIsEditing, setMarkerBeingAdded, setSelectedMarkerId, close]);

  const setupNewLink = (e: MouseEvent<SVGSVGElement>) => {
    if (!isEditing) {
      return;
    }

    const bounds = e.currentTarget.getBoundingClientRect();
    setMarkerBeingAdded({ x: (e.clientX - bounds.x)/bounds.width*100, y: (e.clientY - bounds.y)/bounds.width*100 });
    setSelectedMarkerId(undefined);
  };

  const finishNewMarker = () => {
    addMarker(item.id, markerBeingAdded!.link, markerBeingAdded!.name!, markerBeingAdded!.x, markerBeingAdded!.y);
    setMarkerBeingAdded(undefined);
  };

  const markerClick = (e: MouseEvent, markerId: string) => {
    if (isEditing) {
      setSelectedMarkerId(markerId);
      setMarkerBeingAdded(undefined);
    } else if (markerId) {
      const marker = showMarkers.find(m => m.markerId === markerId);
      if (marker!.link) {
        openItem(marker!.link.id);
        if (!keepOpen) {
          close();
        }
      }
      
    }
    e.stopPropagation();
  };

  const deleteAll = () => {
    if (window.confirm("Are you sure you want to delete all markers from this image?")) {
      for (const marker of markers) {
        removeMarker(item.id, marker.markerId);
      }
    }
  };

  const showMarkers: ShowMarker[] = [...markers];
  if (markerBeingAdded) {
    showMarkers.push({ type: 'selected', name: 'NEW LINK', position: markerBeingAdded });
  }

  return (
    <div className="imagedialog">
      <div className="toolbar">
        { document.fullscreenEnabled && <button onClick={() => setShowcase(true)}><FontAwesomeIcon icon={faImage as IconProp} /></button> }
        { showcase && <ImageShowcase imageUrl={imageFileUrl} close={() => setShowcase(false)} /> }
        { selectedMarkerId && <button onClick={() => { removeMarker(item.id, selectedMarkerId); setSelectedMarkerId(undefined); }}>Delete selected</button> }
        { isEditing && <button className="deleteall" onClick={deleteAll}>Delete ALL</button> }
        { isEditing ? <button onClick={() => { setIsEditing(false); setMarkerBeingAdded(undefined); setSelectedMarkerId(undefined); }}>Stop edit</button> : <button onClick={() => setIsEditing(true)}>Edit</button> }
        <div>
          <label htmlFor="markersize">Marker size:&nbsp;</label>
          <select id="markersize" value={markerSize} onChange={(e) => setMarkerSize(e.target.value as keyof typeof sizeParams)}>
            <option key="small" value="small">Small</option>
            <option key="medium" value="medium">Medium</option>
            <option key="large" value="large">Large</option>
          </select>
        </div>
        <div>
          <input type="checkbox" id="keepopen" value={keepOpen ? "checked" : ""} onChange={(e) => setKeepOpen(!keepOpen)}></input>
          <label htmlFor="keepopen" style={{ cursor: "pointer" }}>Keep open</label>
        </div>
        <div>
          <input type="checkbox" id="hidemarkers" value={hideMarkers ? "checked" : ""} onChange={(e) => setHideMarkers(!hideMarkers)}></input>
          <label htmlFor="hidemarkers" style={{ cursor: "pointer" }}>Hide markers</label>
        </div>
        <button onClick={close}>Close</button>
      </div>
      <div className={"image " + (isEditing ? "editing" : "")} ref={containerRef}>
        <img alt="Canvas for link pinning" src={imageFileUrl} ref={imgRef}></img>
        <svg className="itempins" viewBox={`0 0 100 ${100/imageAspectRatio}`} ref={svgRef} onClick={setupNewLink}>
        {
          !hideMarkers && showMarkers.map(m =>
            <Fragment key={m.markerId ?? "new"}>
              <circle key={"c" + m.markerId} className={isEditing || m.link ? "clickable":""} onClick={(e) => m.markerId && markerClick(e, m.markerId)} r={sizeParams[markerSize].radius} cx={m.position.x} cy={m.position.y} fill={circleColors[selectedMarkerId === m.markerId ? "selected" : m.type]} stroke="black" strokeWidth={sizeParams[markerSize].circleStrokeWidth} opacity="0.8"/>
              <text key={"s" + m.markerId} className="shadow" x={m.position.x + sizeParams[markerSize].xOffset * (m.position.x < 50 ? 1 : -1)} y={m.position.y + sizeParams[markerSize].yOffset} fontSize={sizeParams[markerSize].fontSize} fontWeight="bold" textAnchor={m.position.x < 50 ? "start" : "end"} fill="black" stroke="black" strokeWidth={sizeParams[markerSize].textStrokeWidthBackground}>{m.name}</text>
              <text key={"t" + m.markerId} className={isEditing || m.link ? "clickable":""} onClick={(e) => m.markerId && markerClick(e, m.markerId)} x={m.position.x + sizeParams[markerSize].xOffset * (m.position.x < 50 ? 1 : -1)} y={m.position.y + sizeParams[markerSize].yOffset} fontSize={sizeParams[markerSize].fontSize} fontWeight="bold" textAnchor={m.position.x < 50 ? "start" : "end"} fill={textColors[selectedMarkerId === m.markerId ? "selected" : m.type]} stroke="#000" strokeWidth={sizeParams[markerSize].textStrokeWidthForeground}>{m.name}</text>
            </Fragment>
          )
        }
        </svg>
      </div>
      { markerBeingAdded &&
        <div className="addmarkerdialog">
          <div>
            <label htmlFor="newlink">Item to link to:</label>
            <select id="newlink" value={markerBeingAdded.link} onChange={(e) => { setMarkerBeingAdded({ ...markerBeingAdded, link: e.target.value }) }}>
              <option key="none" value="">NO LINK</option>
              {
                potentialLinksGrouped.map(group =>
                  <optgroup label={group.heading} key={group.heading}>
                  { group.items.map (otherItem => <option key={otherItem.id} value={otherItem.id}>{otherItem.name}</option>) }
                  </optgroup>)
              }
            </select>
          </div>
          {
            markerBeingAdded.link && <div>
              <div>
                <input type="checkbox" id="overridename" value={markerBeingAdded.overrideName ? "checked" : ""} onChange={(e) => setMarkerBeingAdded({ ...markerBeingAdded, overrideName: e.target.checked })} />
                <label htmlFor="overridename">Override name</label>
              </div>
            </div>
          }
          {
            (!markerBeingAdded.link || markerBeingAdded.overrideName) && <div>
              <label htmlFor="newname">Marker name*</label>
              <input type="text" id="newname" placeholder="Required" value={markerBeingAdded.name||''} onChange={(e) => setMarkerBeingAdded({ ...markerBeingAdded, name: e.target.value })} />
            </div>
          }
          <div className="addmarkerbuttons">
            <button onClick={() => (markerBeingAdded.link || markerBeingAdded.name) && finishNewMarker()} disabled={(!markerBeingAdded.link && !markerBeingAdded.name) || (markerBeingAdded.overrideName && !markerBeingAdded.name)}>Add marker</button>
            <button onClick={() => setMarkerBeingAdded(undefined)}>Cancel</button>
          </div>
        </div>
      }
    </div>
  );
}

export default ImageDialog;