import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash, faStar as faStarOutline } from '@fortawesome/free-regular-svg-icons';
import { faEllipsisVertical, faImage, faStar as faStarFilled, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AllHtmlEntities } from 'html-entities';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { linkFinder } from '../biz/linkFinder';
import ImageShowcase from '../image/ImageShowcase';
import { LookupTree, QuestQuillItem } from '../types/types';
import './ViewItem.css';

interface ShowText {
  __html: string;
}

const entities = new AllHtmlEntities();

function ViewItem({
  item,
  focus,
  itemLookupTree,
  close,
  edit,
  editLinks,
  editAliases,
  deleteItem,
  toggleArchiveItem,
  toggleStarItem,
  link,
  imageUrl,
  zoomImage
} : {
  item: QuestQuillItem,
  focus: boolean,
  itemLookupTree: LookupTree,
  close: () => void,
  edit: () => void,
  editLinks: () => void,
  editAliases: () => void,
  deleteItem: (itemId: string) => void,
  toggleArchiveItem: (item: QuestQuillItem) => void,
  toggleStarItem: (item: QuestQuillItem) => void,
  link: (itemId: string) => void,
  imageUrl: (fileName: string) => string,
  zoomImage: (item: QuestQuillItem, url: string) => void
}) {
  const [showText, setShowText] = useState<ShowText>({ __html: entities.encode(item.text) });
  const [showcaseImage, setShowcaseImage] = useState<boolean>(false);
  const [showImage, setShowImage] = useState<boolean>(true);
  
  useEffect(() => {
    const textWithLinks = linkFinder(itemLookupTree, item.text, item.id).output.replaceAll("\n", "<br>");
    setShowText({ __html: textWithLinks });
  }, [item, itemLookupTree]);

  const tryLinking = useCallback((e: MouseEvent<HTMLDivElement>) => {
    if (e.target && e.target instanceof HTMLAnchorElement && e.target.dataset.id) {
      link(e.target.dataset.id);
    }
  }, [link]);

  const deleteCallback = useCallback(() => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      deleteItem(item.id);
    }
  }, [item, deleteItem]);

  return <>
    { showcaseImage && <ImageShowcase imageUrl={imageUrl(item.imageFileName!)} close={() => setShowcaseImage(false)} /> }
    <div className="formrow namerow">
      <span className="name">{item.name}</span>
      <div className="aliasbutton" title="Name aliases" onClick={editAliases}><FontAwesomeIcon icon={faEllipsisVertical as IconProp}></FontAwesomeIcon></div>
    </div>
    <div className="formrow grow view"><div tabIndex={1} dangerouslySetInnerHTML={showText} onClick={tryLinking}></div></div>
    { item.imageFileName &&
      <div className="formrow">
        <div className={"image " + (showImage ? "" : "hidden" )}>
          { document.fullscreenEnabled && <div className="showcasebutton" onClick={() => setShowcaseImage(true)}><FontAwesomeIcon icon={faImage as IconProp} /></div> }
          <div className="showhidebutton" onClick={() => setShowImage(!showImage)}><FontAwesomeIcon icon={(showImage ? faEyeSlash : faEye) as IconProp} /></div>
          <img src={imageUrl(item.imageFileName)} alt={item.name} onClick={() => zoomImage(item, imageUrl(item.imageFileName!))}></img>
        </div>
      </div> }
    <div className="buttonrow reverse">
      <div className="buttoncontainer"><button onClick={editLinks}>Links</button>{item.archived || <button onClick={edit}>Edit</button>}<button onClick={close}>{focus ? "Close focus" : "Close"}</button></div>
      <div className="buttoncontainer grow">
        <button onClick={() => toggleArchiveItem(item)}>{item.archived ? "Unarchive" : "Archive"}</button>
        <button className="delete" onClick={deleteCallback}><FontAwesomeIcon icon={faTrash as IconProp}></FontAwesomeIcon></button>
        <button className="star" onClick={() => toggleStarItem(item)}><FontAwesomeIcon icon={(item.starred ? faStarFilled : faStarOutline) as IconProp}></FontAwesomeIcon></button>
      </div>
    </div>
  </>;
}

export default ViewItem;