import { useEffect, useRef } from "react"

function ImageShowcase({imageUrl, close} : {imageUrl: string, close: () => void }) {
    const imageRef = useRef<HTMLImageElement>(null);
    useEffect(() => {
        const listener = () => {
            if (!document.fullscreenElement) {
                close();
            }
        };
        document.addEventListener("fullscreenchange", listener);
        if (imageRef.current && !document.fullscreenElement) {
            imageRef.current.requestFullscreen();
        }
        return () => { document.removeEventListener("fullscreenchange", listener); }
    }, [imageRef, close])
    return <img alt="showcase" style={{ cursor: "zoom-out" }} src={imageUrl} ref={imageRef} onClick={close}></img>
}

export default ImageShowcase;