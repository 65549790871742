import { useCallback, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './CreateAdventure.css';
import backend from './backend';

function CreateAdventure() {
  const [adventureName, setAdventureName] = useState<string>("");
  const navigate = useNavigate();

  const createAdventure = useCallback(() => {
    if (adventureName.trim()) {
      (async () => {
        const response = await backend.createAdventure(adventureName);
        if (response.ok) {
          navigate(`/${(await response.json()).id}`);
        }
      })();
    }
  }, [adventureName, navigate]);

  return (
    <div className="newadventurecontainer">
      <div className="spacer"></div>
      <h1>QuestQuill</h1>
      <div className="newadventureform">
        <div className="newadventureinputcolumn">
          <div className="newadventureinputrow">
            <span>New adventure:</span>
            <input type="text" placeholder='name of adventure' value={adventureName} onChange={e => setAdventureName(e.target.value)} autoFocus />
          </div>
        </div>
        <div className="newadventurebuttoncolumn">
          <button onClick={createAdventure} className={adventureName === "" ? "disabled": ""}>Create</button>
        </div>
      </div>
      <div className="newadventurereminder">After creating your adventure you must save the resulting URL, perhaps by bookmarking it. It is the only way to find it again.</div>
      <div className="spacer"></div>
    </div>
  );
}

export default CreateAdventure;
