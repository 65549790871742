import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload, faEraser, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Adventure.css';
import backend from './backend';
import { isNewItemId } from './biz/itemUtil';
import { makeLookupTreeFromItems } from './biz/linkFinder';
import ImageDialog from './image/ImageDialog';
import ItemBox from './itembox/ItemBox';
import Search from './Search';
import SideList from './sidelist/SideList';
import { LookupTree, QuestQuillItem, QuestQuillTypes, SavingItem } from './types/types';

const itemSortingFunction = (a: QuestQuillItem, b: QuestQuillItem) => {
  if (!!a.archived !== !!b.archived) {
    return a.archived ? 1 : -1;
  } else if (!!a.starred !== !!b.starred) {
    return a.starred ? -1 : 1;
  } else {
    return a.name.localeCompare(b.name);
  }
};

const nonAlphabeticSortingFunction = (a: QuestQuillItem, b: QuestQuillItem) => {
  if (!!a.archived !== !!b.archived) {
    return a.archived ? 1 : -1;
  } else if (!!a.starred !== !!b.starred) {
    return a.starred ? -1 : 1;
  } else {
    return 0;
  }
};

const loadEverything = async (
  adventureId: string,
  setName: (name: string) => void,
  setQuests: (items: QuestQuillItem[]) => void,
  setPeopleAndOrgs: (items: QuestQuillItem[]) => void,
  setPlaces: (items: QuestQuillItem[]) => void,
  setMisc: (items: QuestQuillItem[]) => void,
  setLogEntries: (items: QuestQuillItem[]) => void,
  setItemLookupTree: (lookupTree: LookupTree) => void
) => {
  const response = await backend.loadAdventure(adventureId);
  if (response.ok) {
    const adventure = await response.json();
    setName(adventure.adventure.name);
    setQuests(adventure.quests.sort(nonAlphabeticSortingFunction));
    setPeopleAndOrgs(adventure.peopleAndOrgs.sort(itemSortingFunction));
    setPlaces(adventure.places.sort(itemSortingFunction));
    setMisc(adventure.misc.sort(itemSortingFunction));
    setLogEntries(adventure.logEntries.reverse().sort(nonAlphabeticSortingFunction));

    setItemLookupTree(makeLookupTreeFromItems([...adventure.quests, ...adventure.peopleAndOrgs, ...adventure.places, ...adventure.misc]));
    return adventure;
  }

  throw new Error("Failed to load adventure data from backend");
};

let editProtectionIds: string[] = [];

const reconstituteItemsOpen = (
  setItemsOpen: React.Dispatch<React.SetStateAction<QuestQuillItem[]>>,
  quests: QuestQuillItem[],
  peopleAndOrgs: QuestQuillItem[],
  places: QuestQuillItem[],
  misc: QuestQuillItem[],
  logEntries: QuestQuillItem[],
  removeItemId?: string,
  extraItemId?: string
) => {
  if (removeItemId === extraItemId) {
    removeItemId = undefined;
    extraItemId = undefined;
  }
  const allItems = [...quests, ...peopleAndOrgs, ...places, ...misc, ...logEntries];
  const extraItems = (extraItemId ? [{ id: extraItemId }] : []);
  setItemsOpen((itemsOpen: QuestQuillItem[]) => {
    const openItemsWithoutRemoved = itemsOpen.filter(item => item.id !== removeItemId);
    const updatedAndPreserved = [...openItemsWithoutRemoved, ...extraItems]
      .map(oldItem => (
        (!editProtectionIds.find(id => id === oldItem.id) && allItems.find(newItem => newItem.id === oldItem.id)) || oldItem
      ) as QuestQuillItem);
    return updatedAndPreserved;
  });
  return allItems;
};

function Adventure() {
  const params = useParams();
  const adventureId = params.id!;
  const [name, setName] = useState<string>("");
  const [quests, setQuests] = useState<QuestQuillItem[]>([]);
  const [peopleAndOrgs, setPeopleAndOrgs] = useState<QuestQuillItem[]>([]);
  const [places, setPlaces] = useState<QuestQuillItem[]>([]);
  const [misc, setMisc] = useState<QuestQuillItem[]>([]);
  const [logEntries, setLogEntries] = useState<QuestQuillItem[]>([]);
  const [focusType, setFocusType] = useState<QuestQuillTypes|null>(null);
  const [itemsOpen, setItemsOpen] = useState<QuestQuillItem[]>([]);
  const [itemLookupTree, setItemLookupTree] = useState<LookupTree>(new Map());
  const [imageDialogState, setImageDialogState] = useState<{ item: QuestQuillItem, imageUrl: string}|undefined>();
  const [flashValues, setFlashValues] = useState<{ [key: string]: number }>({});
  const [showSearch, setShowSearch] = useState<boolean>(false);

  useEffect(() => {
    loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree]);

  const addQuest = useCallback(() => {
    setItemsOpen((itemsOpen) => [...itemsOpen, {
      id: '*' + Date.now(),
      type: 'quest',
      name: '',
      text: '',
      links: [],
      aliases: [],
      archived: false,
      markers: {}
    }]);
  }, [setItemsOpen]);

  const addPersonOrOrg = useCallback(() => {
    setItemsOpen((itemsOpen) => [...itemsOpen, {
      id: '*' + Date.now(),
      type: 'personororg',
      name: '',
      text: '',
      links: [],
      aliases: [],
      archived: false,
      markers: {}
    }]);
  }, [setItemsOpen]);

  const addPlace = useCallback(() => {
    setItemsOpen((itemsOpen) => [...itemsOpen, {
      id: '*' + Date.now(),
      type: 'place',
      name: '',
      text: '',
      links: [],
      aliases: [],
      archived: false,
      markers: {}
    }]);
  }, [setItemsOpen]);

  const addMiscItem = useCallback(() => {
    setItemsOpen((itemsOpen) => [...itemsOpen, {
      id: '*' + Date.now(),
      type: 'miscitem',
      name: '',
      text: '',
      links: [],
      aliases: [],
      archived: false,
      markers: {}
    }]);
  }, [setItemsOpen]);

  const addLogEntry = useCallback(() => {
    setItemsOpen((itemsOpen) => [...itemsOpen, {
      id: '*' + Date.now(),
      type: 'logentry',
      name: '',
      text: '',
      links: [],
      aliases: [],
      archived: false,
      markers: {}
    }]);
  }, [setItemsOpen]);

  useEffect(() => {
    const keyHandler = (e: KeyboardEvent) => {
      if (e.shiftKey && (e.ctrlKey || e.metaKey)) {
        if (e.key === 'F' || e.key === "f") {
          setShowSearch(true);
          e.preventDefault();
        }
        if (e.key === 'D' || e.key === "d") {
          setItemsOpen([]);
          e.preventDefault();
        }
        if (e.key === 'U' || e.key === "u") {
          addQuest();
          e.preventDefault();
        }
        if (e.key === 'O' || e.key === "o") {
          addPersonOrOrg();
          e.preventDefault();
        }
        if (e.key === 'P' || e.key === "p") {
          addPlace();
          e.preventDefault();
        }
        if (e.key === 'M' || e.key === "m") {
          addMiscItem();
          e.preventDefault();
        }
        if (e.key === 'L' || e.key === "l") {
          addLogEntry();
          e.preventDefault();
        }
      } else if (e.keyCode === 27) {
        setShowSearch(false);
      }
    };
    window.addEventListener("keydown", keyHandler);
    return () => {
      window.removeEventListener("keydown", keyHandler);
    }
  }, [setShowSearch, setItemsOpen, addQuest, addPersonOrOrg, addPlace, addMiscItem, addLogEntry]);

  const setEditProtection = useCallback((itemId: string, value: boolean) => {
    if (value) {
      if (!editProtectionIds.find(id => id === itemId)) {
        editProtectionIds.push(itemId);
      }
    } else {
      editProtectionIds = editProtectionIds.filter(id => id !== itemId);
    }
  }, []);

  const saveItem = useCallback((savedItem: SavingItem) => {
    (async () => {
      const saveResponse = isNewItemId(savedItem.id)
        ? await backend.insertItem(adventureId, savedItem)
        : await backend.updateItem(adventureId, savedItem);
      if (!saveResponse.ok) {
        return;
      }
      const adventure = await loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
      reconstituteItemsOpen(setItemsOpen, adventure.quests, adventure.peopleAndOrgs, adventure.places, adventure.misc, adventure.logEntries, savedItem.id, isNewItemId(savedItem.id) ? (await saveResponse.json()).id : savedItem.id);
    })();
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree, setItemsOpen]);

  const openItem = useCallback((itemId: string) => {
    const item = [...quests, ...peopleAndOrgs, ...places, ...misc, ...logEntries].find(item => item.id === itemId);
    if (item) {
      setFlashValues((flashValues) => ({ ...flashValues, [item.id]: Date.now()}));
      setItemsOpen((itemsOpen) => {
        if (itemsOpen.find(openItem => openItem.id === itemId)) {
          return itemsOpen;
        } else {
          return [...itemsOpen, item];
        }
      });
    }
  }, [quests, peopleAndOrgs, places, misc, logEntries, setItemsOpen, setFlashValues]);

  const closeItem = useCallback((itemId: string) => {
    setItemsOpen((itemsOpen) => itemsOpen.filter((item) => item.id !== itemId));
  }, [setItemsOpen]);

  const deleteItem = useCallback((itemId: string) => {
    (async () => {
      const deleteResponse = await backend.deleteItem(adventureId, itemId);
      if (!deleteResponse.ok) {
        return;
      }
      const adventure = await loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
      reconstituteItemsOpen(setItemsOpen, adventure.quests, adventure.peopleAndOrgs, adventure.places, adventure.misc, adventure.logEntries, itemId);
    })();
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree, setItemsOpen]);

  const toggleArchiveItem = useCallback((item: QuestQuillItem) => {
    (async () => {
      const archiveResponse = await backend.archiveItem(adventureId, item.id, !item.archived);
      if (!archiveResponse.ok) {
        return;
      }
      const adventure = await loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
      reconstituteItemsOpen(setItemsOpen, adventure.quests, adventure.peopleAndOrgs, adventure.places, adventure.misc, adventure.logEntries);
    })();
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree, setItemsOpen]);

  const toggleStarItem = useCallback((item: QuestQuillItem) => {
    (async () => {
      const starResponse = await backend.starItem(adventureId, item.id, !item.starred);
      if (!starResponse.ok) {
        return;
      }
      const adventure = await loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
      reconstituteItemsOpen(setItemsOpen, adventure.quests, adventure.peopleAndOrgs, adventure.places, adventure.misc, adventure.logEntries);
    })();
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree, setItemsOpen]);

  const uploadImage = useCallback(async (imageUploadFile: File) => {
    const imageUploadResult = await backend.uploadImage(imageUploadFile);
    if (!imageUploadResult.ok) {
      console.log("Failed to upload image");
      return undefined;
    }
    return (await imageUploadResult.json()).fileName;
  }, []);

  const addLink = useCallback((from: string, to: string) => {
    (async () => {
      const linkResponse = await backend.link(adventureId, from, to);
      if (!linkResponse.ok) {
        return;
      }
      const adventure = await loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
      reconstituteItemsOpen(setItemsOpen, adventure.quests, adventure.peopleAndOrgs, adventure.places, adventure.misc, adventure.logEntries);
    })();
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree, setItemsOpen]);

  const removeLink = useCallback((from: string, to: string) => {
    (async () => {
      const unlinkResponse = await backend.unlink(adventureId, from, to);
      if (!unlinkResponse.ok) {
        return;
      }
      const adventure = await loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
      reconstituteItemsOpen(setItemsOpen, adventure.quests, adventure.peopleAndOrgs, adventure.places, adventure.misc, adventure.logEntries);
    })();
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree, setItemsOpen]);

  const setParent = useCallback((itemId: string, parentItemId: string) => {
    (async () => {
      const parentResponse = await backend.setParent(adventureId, itemId, parentItemId);
      if (!parentResponse.ok) {
        return;
      }
      const adventure = await loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
      reconstituteItemsOpen(setItemsOpen, adventure.quests, adventure.peopleAndOrgs, adventure.places, adventure.misc, adventure.logEntries);
    })();
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree, setItemsOpen]);

  const addAlias = useCallback((itemId: string, alias: string) => {
    (async () => {
      const aliasResponse = await backend.addAlias(adventureId, itemId, alias);
      if (!aliasResponse.ok) {
        return;
      }
      const adventure = await loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
      reconstituteItemsOpen(setItemsOpen, adventure.quests, adventure.peopleAndOrgs, adventure.places, adventure.misc, adventure.logEntries);
    })();
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree, setItemsOpen]);

  const removeAlias = useCallback((itemId: string, index: number) => {
    (async () => {
      const removeResponse = await backend.removeAlias(adventureId, itemId, index);
      if (!removeResponse.ok) {
        return;
      }
      const adventure = await loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
      reconstituteItemsOpen(setItemsOpen, adventure.quests, adventure.peopleAndOrgs, adventure.places, adventure.misc, adventure.logEntries);
    })();
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree, setItemsOpen]);

  const addMarker = useCallback((itemId: string, toItemId: string|undefined, name: string, x: number, y: number) => {
    (async () => {
      if (!imageDialogState) {
        throw new Error("Unexpected state - expected image dialog state to be filled.")
      }
      if (toItemId) {
        const unlinkResponse = await backend.unlink(adventureId, itemId, toItemId);
        if (!unlinkResponse.ok) {
          return;
        }

        const linkResponse = await backend.link(adventureId, itemId, toItemId);
        if (!linkResponse.ok) {
          return;
        }
      }
      
      const setCoordResponse = await backend.addMarker(adventureId, itemId, toItemId, name, x, y);
      if (!setCoordResponse.ok) {
        return;
      }
      const adventure = await loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
      const newAllItems = reconstituteItemsOpen(setItemsOpen, adventure.quests, adventure.peopleAndOrgs, adventure.places, adventure.misc, adventure.logEntries);
      const updatedImageItem = newAllItems.find(i => i.id === imageDialogState.item.id);
      setImageDialogState({ item: updatedImageItem!, imageUrl: imageDialogState.imageUrl });
    })();
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree, setItemsOpen, imageDialogState, setImageDialogState]);

  const removeMarker = useCallback((itemId: string, markerId: string) => {
    (async () => {
      if (!imageDialogState) {
        throw new Error("Unexpected state - expected image dialog state to be filled.")
      }
      const linkResponse = await backend.removeMarker(adventureId, itemId, markerId);
      if (!linkResponse.ok) {
        return;
      }
      const adventure = await loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
      const newAllItems = reconstituteItemsOpen(setItemsOpen, adventure.quests, adventure.peopleAndOrgs, adventure.places, adventure.misc, adventure.logEntries);
      const updatedImageItem = newAllItems.find(i => i.id === imageDialogState.item.id);
      setImageDialogState({ item: updatedImageItem!, imageUrl: imageDialogState.imageUrl });
    })();
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree, setItemsOpen, imageDialogState, setImageDialogState]);

  const exportData = useCallback(() => {
    (async () => {
      const format = new Intl.DateTimeFormat('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false // 24-hour format
      });

      const exportResponse = await backend.fetchDataExport(adventureId);
      if (exportResponse.ok) {
        const objectUrl = window.URL.createObjectURL(await exportResponse.blob());
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = objectUrl;
        link.download = `QuestQuill ${name} - ${format.format(new Date()).replace(/:/g, ' ')}.json`;
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      }
    })();
  }, [adventureId, name]);

  return <div id="adventurepage">
    <div id="sidemenu">
      { (!focusType || focusType === 'quest') && <SideList heading="Quests" extraClass="quests" isFocused={focusType === 'quest'} addClick={addQuest} selectClick={(itemId: string) => openItem(itemId)} items={quests} showProperty="name" focus={() => setFocusType(!focusType && "quest" || null)} /> }
      { (!focusType || focusType === 'personororg') && <SideList heading="People & Orgs" extraClass="peopleandorgs" isFocused={focusType === 'personororg'} addClick={addPersonOrOrg} selectClick={(itemId: string) => openItem(itemId)} items={peopleAndOrgs} showProperty="name" focus={() => setFocusType(!focusType && "personororg" || null)} /> }
      { (!focusType || focusType === 'place') && <SideList heading="Places" extraClass="places" isFocused={focusType === 'place'} addClick={addPlace} selectClick={(itemId: string) => openItem(itemId)} items={places} showProperty="name" focus={() => setFocusType(!focusType && "place" || null)} /> }
      { (!focusType || focusType === 'miscitem') && <SideList heading="Miscellaneous" extraClass="misc" isFocused={focusType === 'miscitem'} addClick={addMiscItem} selectClick={(itemId: string) => openItem(itemId)} items={misc} showProperty="name" focus={() => setFocusType(!focusType && "miscitem" || null)} /> }
      { (!focusType || focusType === 'logentry') && <SideList heading="Log" extraClass="logentries" isFocused={focusType === 'logentry'} addClick={addLogEntry} selectClick={(itemId: string) => openItem(itemId)} items={logEntries} showProperty="name" focus={() => setFocusType(!focusType && "logentry" || null)} /> }
    </div>
    <div id="mainarea">
      <div id="topbar">
        <div id="adventurename">QuestQuill: {name}</div>
        <div id="toolbar">
          <div className="toolbarbutton" title="Clear workspace"><FontAwesomeIcon icon={faEraser as IconProp} onClick={() => setItemsOpen(itemsOpen.filter(item => editProtectionIds.find(id => id === item.id)))} /></div>
          <div className="toolbarbutton" title="Search"><FontAwesomeIcon icon={faMagnifyingGlass as IconProp} onClick={() => setShowSearch(true)} /></div>
          <div className="toolbarbutton" title="Download"><FontAwesomeIcon icon={faDownload as IconProp} onClick={() => exportData()} /></div>
        </div>
      </div>
      {
        itemsOpen.map(item =>
          <ItemBox
            key={item.id}
            item={item}
            itemLookupTree={itemLookupTree}
            cancel={closeItem}
            save={saveItem}
            setEditProtection={setEditProtection}
            deleteItem={deleteItem}
            toggleArchiveItem={toggleArchiveItem}
            toggleStarItem={toggleStarItem}
            link={openItem}
            addLink={addLink}
            removeLink={removeLink}
            setParent={setParent}
            addAlias={addAlias}
            removeAlias={removeAlias}
            uploadImage={uploadImage}
            imageUrl={(name) => backend.getImageUrl(name)}
            zoomImage={(item, imageUrl) => { setImageDialogState({ item, imageUrl }) }}
            flash={flashValues[item.id]}
            allItems={[...quests, ...peopleAndOrgs, ...places, ...misc, ...logEntries]}>
          </ItemBox>) }
    </div>
    { imageDialogState && <ImageDialog item={imageDialogState.item} allItems={[...quests, ...peopleAndOrgs, ...places, ...misc, ...logEntries]} imageFileUrl={imageDialogState.imageUrl} openItem={(id) => { openItem(id); }} close={() => setImageDialogState(undefined)} addMarker={addMarker} removeMarker={removeMarker}></ImageDialog> }
    { showSearch && <Search quests={quests} peopleAndOrgs={peopleAndOrgs} places={places} misc={misc} logEntries={logEntries} openItem={(id) => { openItem(id); }} close={() => setShowSearch(false)}></Search> }
  </div>
}

export default Adventure;