import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faStar, } from '@fortawesome/free-regular-svg-icons';
import { faCaretDown, faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEvent, useCallback, useState } from 'react';
import { QuestQuillItem } from '../types/types';
import './SideList.css';

function SideListNode({
  item,
  items,
  selectClick,
  showProperty
} : {
  item: QuestQuillItem,
  items: QuestQuillItem[],
  selectClick: (itemId: string) => void,
  showProperty: keyof QuestQuillItem
}) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const children: QuestQuillItem[] = items.filter(i => i.parent === item.id && !i.archived);

  const expand = useCallback((e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    setExpanded((expanded) => !expanded);
  }, [setExpanded]);

  return <>
    <li
      key={item.id}
      className={item.archived ? "archived" : ""}
      onClick={() => { selectClick(item.id); if (!expanded) { setExpanded(true); }}}>
        { item.starred && <span className='icon'><FontAwesomeIcon icon={faStar as IconProp}/></span> }
        {item[showProperty] as string}
        { children.length > 0 && <span className={expanded ? 'collapse' : 'expand'} onClick={expand}><FontAwesomeIcon icon={(expanded ? faCaretDown : faCaretLeft) as IconProp}/></span> }
    </li>
    { expanded && children.length > 0 &&
      <ul className="children">
        { children.map(item => <SideListNode key={item.id} items={items} item={item} selectClick={selectClick} showProperty={showProperty} />) }
      </ul>
    }
    </>;
}

export default SideListNode;