import { useState, useCallback, ChangeEvent } from 'react';
import './Search.css';
import { QuestQuillItem } from './types/types';

interface SearchResults {
  item: {
    id?: string;
    type?: string;
    name: string;
  };
  nameHit?: boolean;
  excerpt?: string;
}

function Search({
  quests,
  peopleAndOrgs,
  places,
  misc,
  logEntries,
  openItem,
  close
} : {
  quests: QuestQuillItem[],
  peopleAndOrgs: QuestQuillItem[],
  places: QuestQuillItem[],
  misc: QuestQuillItem[],
  logEntries: QuestQuillItem[],
  openItem: (itemId: string) => void,
  close: () => void
}) {
  const [searchString, setSearchString] = useState<string>("");
  const [searchResult, setSearchResult] = useState<SearchResults[]>([...quests, ...peopleAndOrgs, ...places, ...misc, ...logEntries].map(item => (
    {
      item,
      excerpt: item.text.substring(0, 80)
    }
  )).sort((a, b) => a.item.name.localeCompare(b.item.name)));

  const search = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
    if (e.target.value.trim() === '') {
      setSearchResult([...quests, ...peopleAndOrgs, ...places, ...misc, ...logEntries].map(item => (
        {
          item,
          excerpt: item.text.substring(0, 80)
        }
      )).sort((a, b) => a.item.name.localeCompare(b.item.name)));
      return;
    }
    const lowercaseSearchString = e.target.value.toLowerCase();
    const newSearchResult: SearchResults[] = [];
    const handleItem = (item: QuestQuillItem) => {
      const textHitPos: number = item.text.toLowerCase().indexOf(lowercaseSearchString);
      const nameHit: boolean = item.name.toLowerCase().indexOf(lowercaseSearchString) > -1;
      const aliasHit: boolean = item.aliases.some(alias => alias.toLowerCase().indexOf(lowercaseSearchString) > -1);
      if (textHitPos > -1) {
        const excerptStartPos = Math.max(0, textHitPos - 25);
        const excerpt = item.text.substring(excerptStartPos, excerptStartPos + 80);
        newSearchResult.push({ item, excerpt: (excerptStartPos === 0 ? "" : "\u2026") + excerpt, nameHit: nameHit || aliasHit });
      } else if (nameHit || aliasHit) {
        newSearchResult.push({ item, excerpt: item.text.substring(0, 80), nameHit: nameHit || aliasHit });
      }
    };
    for (const item of quests) {
      handleItem(item);
    }
    for (const item of peopleAndOrgs) {
      handleItem(item);
    }
    for (const item of places) {
      handleItem(item);
    }
    for (const item of misc) {
      handleItem(item);
    }
    for (const item of logEntries) {
      handleItem(item);
    }
    if (newSearchResult.length === 0) {
      newSearchResult.push({ item: { id: undefined, name: "No results" }, excerpt: "" });
    }
    setSearchResult(newSearchResult.sort((a, b) => a.nameHit === b.nameHit ? a.item.name.localeCompare(b.item.name) : (a.nameHit ? -1 : 1)));
  }, [setSearchString, quests, peopleAndOrgs, places, misc, logEntries]);

  return <div className="searchbackdrop" onClick={close}>
    <div className="search" onClick={(e) => e.stopPropagation()}>
      <div className="searchbox"><input type="text" value={searchString} placeholder="Search..." autoFocus onChange={search}></input></div>
      <div className="searchresults">
        {
          searchResult.map(result =>
            <div key={result.item.id} className={"searchitem " + (result.item.type ?? "")} onClick={() => result.item.id && openItem(result.item.id)}>
              <div className="resultname">{result.item.name}</div>
              <div className="resultexcerpt">{result.excerpt}</div>
            </div>
          )
        }
      </div>
    </div>
  </div>;
}

export default Search;