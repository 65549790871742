import { useCallback, useEffect, useRef, useState } from 'react';
import { QuestQuillItem, SavingItem } from '../types/types';
import './EditItem.css';

function EditItem({
  item,
  cancel,
  save,
  uploadImage,
  toggleFocus
} : {
  item: QuestQuillItem,
  cancel: () => void,
  save: (item: SavingItem) => void,
  uploadImage: (file: File) => Promise<string|undefined>,
  toggleFocus: () => void
}) {
  const [name, setName] = useState<string>(item.name);
  const [text, setText] = useState<string>(item.text);
  const [imageFileName, setImageFileName] = useState<string|undefined>(item.imageFileName);
  const [imageUploadFile, setImageUploadFile] = useState<File>();
  const [showImageRow, setShowImageRow] = useState<boolean>(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setName(item.name);
    setText(item.text);
    setImageFileName(item.imageFileName);
    setImageUploadFile(undefined);
    setShowImageRow(false);
  }, [item]);

  const saveCallback = useCallback(() => {
    (async () => {
      let savedImageFileName = imageFileName;
      if (imageUploadFile) {
        savedImageFileName = await uploadImage(imageUploadFile);
      }

      save({
        id: item.id,
        type: item.type,
        imageFileName: savedImageFileName,
        name,
        text
      });
    })();
  }, [item, name, text, imageFileName, imageUploadFile, save, uploadImage]);

  useEffect(() => {
    const keyHandler = (e: KeyboardEvent) => {
      if (e.keyCode === 27 && text === item.text && name === item.name) {
        cancel();
      }
      if (e.shiftKey && (e.ctrlKey || e.metaKey)) {
        if (e.key === 'S' || e.key === "s") {
          saveCallback();
          e.preventDefault();
        }
        if (e.key === 'Enter') {
          toggleFocus();
          e.preventDefault();
        }
      }
    };
    textAreaRef.current?.addEventListener("keydown", keyHandler, true);
  }, [textAreaRef, text, name, item, cancel, saveCallback, toggleFocus]);

  return <>
    <div className="formrow"><input autoFocus placeholder="Name..." id="itemname" className={"grow itemname " + (name === item.name ? "" : "dirty")} value={name} onChange={(e) => setName(e.target.value)}></input></div>
    <div className="formrow grow shadowcontainer">
      <textarea className={text === item.text ? "" : "dirty"} placeholder="Description..." value={text} onChange={(e) => setText(e.target.value)} ref={textAreaRef}></textarea>
      <div className="shadowtext">{text}</div>
    </div>
    {
      showImageRow && <div className="formrow">
        <label>Image:</label><input type="file" accept=".png, .apng, .avif, .jpg, .jpeg, .gif, .webp, .svg" onChange={(e) => setImageUploadFile(e.target.files![0])}></input>
      </div>
    }
    <div className="buttonrow">
      <div className="buttoncontainer grow">
        {
          imageFileName
            ? <button onClick={() => setImageFileName(undefined)}>Remove image</button>
            : <button onClick={() => setShowImageRow(true)}>Add image</button>
        }
      </div>
      <div className="buttoncontainer"><button onClick={saveCallback} className="save">Save</button><button className="cancel" onClick={cancel}>Cancel</button></div>
    </div>
  </>;
}

export default EditItem;