import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSquareCaretLeft, faSquareCaretRight } from '@fortawesome/free-regular-svg-icons';
import { faExpand as faMaximize, faCompress as faMinimize } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { isNewItemId } from '../biz/itemUtil';
import { LookupTree, QuestQuillItem, SavingItem } from '../types/types';
import AliasItem from './AliasItem';
import EditItem from './EditItem';
import './ItemBox.css';
import LinkItem from './LinkItem';
import ViewItem from './ViewItem';

type BoxStates = 'editing'|'linking'|'aliasing'|'viewing';

const headings = {
  'quest': 'Quest',
  'personororg': 'Person or Organisation',
  'miscitem': 'Miscellaneous item',
  'place': 'Place',
  'logentry': 'Log entry'
};

const headingPrefixes = {
  editing: 'Editing: ',
  linking: 'Linking: ',
  aliasing: 'Aliases: ',
  viewing: ''
};

function ItemBox({
  item,
  itemLookupTree,
  cancel,
  save,
  setEditProtection,
  deleteItem,
  toggleStarItem,
  toggleArchiveItem,
  link,
  addLink,
  removeLink,
  setParent,
  addAlias,
  removeAlias,
  uploadImage,
  imageUrl,
  zoomImage,
  flash,
  allItems
} : {
  item : QuestQuillItem,
  itemLookupTree : LookupTree,
  cancel: (itemId: string) => void,
  save: (item: SavingItem) => void,
  setEditProtection: (itemId: string, protect: boolean) => void,
  deleteItem: (itemId: string) => void,
  toggleStarItem: (item: QuestQuillItem) => void,
  toggleArchiveItem: (item: QuestQuillItem) => void,
  link: (itemId: string) => void,
  addLink: (itemId: string, otherId: string) => void,
  removeLink: (itemId: string, otherId: string) => void,
  setParent: (itemId: string, parentId: string) => void,
  addAlias: (itemId: string, alias: string) => void,
  removeAlias: (itemId: string, index: number) => void,
  uploadImage: (file: File) => Promise<string|undefined>,
  imageUrl: (fileName: string) => string,
  zoomImage: (item: QuestQuillItem, url: string) => void,
  flash: number,
  allItems: QuestQuillItem[]
}) {
  const [state, setState] = useState<BoxStates>("viewing");
  const [expanded, setExpanded] = useState<boolean>(false);
  const [lastFlash, setLastFlash] = useState<number>(-1);
  const [focus, setFocus] = useState<boolean>(false);
  
  useEffect(() => {
    if (isNewItemId(item.id)) {
      setState("editing");
      setEditProtection(item.id, true);
    } else {
      setState("viewing");
    }
    
    setExpanded(false);
  }, [item.id, setEditProtection])

  const shouldFlash = (lastFlash !== flash);
  if (shouldFlash) {
    const newFlash = flash;
    setTimeout(() => setLastFlash(newFlash), 300);
  }

  return <div className={"outerfocus " + (focus ? "focusitem" : "nofocusitem") + (expanded ? " expanded" : "")}>
    <div className="innerfocus">
      <div className={"itembox " + item.type + " " + (item.archived ? "archived" : "") + " " + (shouldFlash ? "flash" : "") + " " + (state === "editing" ? "editing" : "")}>
        <div className="innercontainer">
          <div className="formrow">
            <span className="type">{headingPrefixes[state] + headings[item.type]}</span>
            <div className="focus" title={focus ? "Unfocus" : "Focus"} onClick={() => setFocus(!focus)}><FontAwesomeIcon icon={(focus ? faMinimize : faMaximize) as IconProp}></FontAwesomeIcon></div>
            {!focus && <div className="resize" title={expanded ? "Narrow" : "Wide"} onClick={() => setExpanded(!expanded)}><FontAwesomeIcon icon={(expanded ? faSquareCaretLeft : faSquareCaretRight) as IconProp}></FontAwesomeIcon></div>}
          </div>
          {
            {
              "editing": () => <EditItem item={item} cancel={() => { setState("viewing"); setEditProtection(item.id, false); if (isNewItemId(item.id)) { cancel(item.id); } }} uploadImage={uploadImage} save={(item) => { setState("viewing"); setEditProtection(item.id, false); save(item); }} toggleFocus={() => setFocus(!focus)} />,
              "viewing": () => <ViewItem item={item} focus={focus} itemLookupTree={itemLookupTree} close={() => { if (focus) { setFocus(false); return; } cancel(item.id); }} edit={() => { setState("editing"); setEditProtection(item.id, true); }} editLinks={() => setState("linking")} editAliases={() => setState("aliasing")} deleteItem={deleteItem} toggleArchiveItem={toggleArchiveItem} toggleStarItem={toggleStarItem} link={link} imageUrl={imageUrl} zoomImage={zoomImage} />,
              "linking": () => <LinkItem item={item} focus={focus} itemLookupTree={itemLookupTree} close={() => { if (focus) { setFocus(false); return; } cancel(item.id); }} back={() => setState("viewing")} link={link} addLink={addLink} removeLink={removeLink} setParent={setParent} allItems={allItems} />,
              "aliasing": () => <AliasItem item={item} focus={focus} close={() => { if (focus) { setFocus(false); return; } cancel(item.id); }} back={() => setState("viewing")} addAlias={addAlias} removeAlias={removeAlias} />
            }[state]()
          }
        </div>
      </div>
    </div>
  </div>;
}

export default ItemBox;